
<script>
import AsideComponent from "@/components/Asideadmin"
import HeadComponent from "@/components/Headadmin"
import FootComponent from "@/components/Footadmin"

import store from "@/store"
import Swal from 'sweetalert2'
import $ from 'jquery'
import axiosWithHeaders, { BASE_API_URL } from '@/services/api'
export default {

    components: {
        HeadComponent,
        AsideComponent,
        FootComponent
    },
    data() {

        return {
            dashboard: null,
            user: null,
            demandes: null,
            role: null,
            nb_contact: 0,
            proprieterstop: null,
            userstop: null,
            
            nb_total_agence: 0,
            nb_total_particulier: 0,
            proprieter_total_nbr_contact: 0,
            user_total_nbr_contact: 0,
            nb_total_like: 0,
            nb_total_comment: 0,
            nb_like: 0,
            nb_comment: 0,
            nb_total_demande: 0,
            nb_total_user: 0,
            nb_bien: 0,
            nb_total_bien: 0


        }
    },
    name: 'DashboardComponent',
    mounted() {
        store.dispatch('auth/_currentUser')
            .then(() => {
                var currentuser = store.getters['auth/getCurrentUser'];
                this.user = currentuser.id
                this.role = currentuser.role
                this.vudemande()

                this.proprietop(),
                    this.usertop(),
                    this.dashboard_info()
            })
            .catch(error => {
                console.log(error)
            })

 

// Demander la permission de notification
if (Notification.permission === 'default') {
    Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
            this.subscribeUser();
        }
    });
}
this.subscribeUser();
    },
    methods: {

        async  subscribeUser() {
    const vapidPublicKey = 'BE2kaI2c3WvbAl8XAYglYWPeWHTcVoR6oRXoqXNB34EDC0fG9CAMzY1M-ANh3dN-wXCXq2sh30Qpxj_xaL2riqw';
    
    // Convertir la clé VAPID en ArrayBuffer
    const applicationServerKey = this.urlBase64ToUint8Array(vapidPublicKey);

    try {
        // Obtenir le service worker actif
        const registration = await navigator.serviceWorker.ready;

        // S'abonner au PushManager
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey,
        });

        console.log('User is subscribed:', subscription);

        // Envoyer l'abonnement au serveur
        await  axiosWithHeaders.post(BASE_API_URL + 'news/add', subscription);

        console.log('Abonnement envoyé au serveur');
    } catch (error) {
        console.error('Erreur lors de l’abonnement :', error);
    }
}
,
// Fonction utilitaire pour convertir une clé base64 URL-safe en ArrayBuffer
urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

,        formatMonetaryValue(value) {
            // Convertit le nombre en chaîne de caractères
            let formattedValue = String(value);

            // Vérifie si la valeur est supérieure à 3 chiffres
            if (formattedValue.length > 3) {
                // Détermine le nombre de groupes de trois chiffres
                const groups = Math.floor((formattedValue.length - 1) / 3);

                // Initialise l'index pour l'insertion de l'espace de mille
                let index = formattedValue.length - 3;

                // Boucle pour insérer les espaces de mille
                for (let i = 0; i < groups; i++) {
                    formattedValue = formattedValue.slice(0, index) + ' ' + formattedValue.slice(index);
                    index -= 3;
                }
            }

            // Retourne la valeur formatée
            return formattedValue;
        },

        upcontact_pro(id) {
            store.dispatch('auth/upcontact_pro', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        upcontact_us(id) {
            store.dispatch('auth/upcontact_us', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        proprietop() {
            store.dispatch('proprieter/proprietop')
                .then(response => {
                    console.log(response)
                    this.proprieterstop = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        usertop() {
            store.dispatch('auth/usertop')
                .then(response => {
                    console.log(response)
                    this.userstop = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        supdemande(id) {
            Swal.fire({
                title: "Attention?",
                text: "Voulez vous supprimer cette demande ?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {
                    store.dispatch('auth/supdemande', { 'id': id })
                        .then(response => {
                            console.log(response)
                            window.location.reload()
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            })
        },

        vudemande() {
            store.dispatch('proprieter/demandes')
                .then(response => {
                    this.demandes = response
                    this.$nextTick(() => {
                        if ($.fn.DataTable.isDataTable('#Table')) {
                            $('#Table').DataTable().destroy();
                        }
                        // Initialisation de DataTable
                        $('#Table').DataTable();
                    });
                })
                .catch(error => {
                    console.log(error)
                })
        },
        dashboard_info() {
            store.dispatch('auth/dashboard')
                .then(response => {

                    this.nb_total_agence = response.nb_total_agence
                    this.nb_total_particulier = response.nb_total_particulier
                    this.proprieter_total_nbr_contact = response.proprieter_total_nbr_contact
                    this.user_total_nbr_contact = response.user_total_nbr_contact
                    this.nb_total_like = response.nb_total_like
                    this.nb_total_comment = response.nb_total_comment
                    this.nb_like = response.nb_like
                    this.nb_comment = response.nb_comment
                    this.nb_total_demande = response.nb_total_demande
                    this.nb_total_user = response.nb_total_user
                    this.nb_bien = response.nb_bien
                    this.nb_total_bien = response.nb_total_bien
                    this.nb_contact = response.nb_contact
                    if(response.nb_attente_bien>0)
                    {
                     Swal.fire({
                                title: "Attention",
                                text: response.nb_attente_bien +' bien en attente de validation',
                                icon: "warning",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                    }
                       if(response.nb_attente_demande>0)
                    {
                     Swal.fire({
                                title: "Attention",
                                text: response.nb_attente_demande +' demande en attente de validation',
                                icon: "warning",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}

</script>

<template>
    <body class="g-sidenav-show  bg-gray-200" id="body">
        <AsideComponent />

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <!-- Navbar -->
            <HeadComponent />
            <!-- End Navbar -->
            <div class="container-fluid py-4">
                <div class="row">
                    <div v-if="role == 'admin'" class="col-xl-3 col-sm-6 mb-xl-0 mb-5">
                        <div class="card">
                            <div class="card-header p-3 pt-2">
                                <div
                                    class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                                    <i class="material-icons opacity-10">group</i>
                                </div>
                                <div class="text-end pt-1">
                                    <p class="text-sm mb-0 text-capitalize">Total d'utilisateur</p>
                                    <h4 class="mb-0">{{ nb_total_user }}</h4>
                                </div>
                            </div>
                            <hr class="dark horizontal my-0">

                        </div>
                    </div>
                    <div v-if="role == 'admin'" class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card">
                            <div class="card-header p-3 pt-2">
                                <div
                                    class="icon icon-lg icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                                    <i class="material-icons opacity-10">person</i>
                                </div>
                                <div class="text-end pt-1">
                                    <p class="text-sm mb-0 text-capitalize">Total de particulier</p>
                                    <h4 class="mb-0">{{ nb_total_particulier }}</h4>
                                </div>
                            </div>
                            <hr class="dark horizontal my-0">

                        </div>
                    </div>
                    <div v-if="role == 'admin'" class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card">
                            <div class="card-header p-3 pt-2">
                                <div
                                    class="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                    <i class="material-icons opacity-10">real_estate_agent</i>
                                </div>
                                <div class="text-end pt-1">
                                    <p class="text-sm mb-0 text-capitalize">Total d'agence</p>
                                    <h4 class="mb-0">{{ nb_total_agence }}</h4>
                                </div>
                            </div>
                            <hr class="dark horizontal my-0">

                        </div>
                    </div>
                    <div v-if="role == 'admin'" class="col-xl-3 col-sm-6">
                        <div class="card">
                            <div class="card-header p-3 pt-2">
                                <div
                                    class="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                    <i class="material-icons opacity-10">contact_phone</i>
                                </div>
                                <div class="text-end pt-1">
                                    <p class="text-sm mb-0 text-capitalize">Utilisateur contacter </p>
                                    <h4 class="mb-0">{{ user_total_nbr_contact }}</h4>
                                </div>
                            </div>
                            <hr class="dark horizontal my-0">

                        </div>
                    </div>


                    <div v-if="role == 'admin'" class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card">
                            <div class="card-header p-3 pt-2">
                                <div
                                    class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                                    <i class="material-icons opacity-10">house</i>
                                </div>
                                <div class="text-end pt-1">
                                    <p class="text-sm mb-0 text-capitalize">Total de bien </p>
                                    <h4 class="mb-0">{{ nb_total_bien }}</h4>
                                </div>
                            </div>
                            <hr class="dark horizontal my-0">

                        </div>
                    </div>
                    <div v-if="role == 'admin'" class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card">
                            <div class="card-header p-3 pt-2">
                                <div
                                    class="icon icon-lg icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                                    <i class="material-icons opacity-10">thumb_up</i>
                                </div>
                                <div class="text-end pt-1">
                                    <p class="text-sm mb-0 text-capitalize">Total des likes</p>
                                    <h4 class="mb-0">{{ nb_total_like }}</h4>
                                </div>
                            </div>
                            <hr class="dark horizontal my-0">

                        </div>
                    </div>
                    <div v-if="role == 'admin'" class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card">
                            <div class="card-header p-3 pt-2">
                                <div
                                    class="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                    <i class="material-icons opacity-10">chat</i>
                                </div>
                                <div class="text-end pt-1">
                                    <p class="text-sm mb-0 text-capitalize">Total des commentaires</p>
                                    <h4 class="mb-0">{{ nb_total_comment }}</h4>
                                </div>
                            </div>
                            <hr class="dark horizontal my-0">

                        </div>
                    </div>
                    <div v-if="role == 'admin'" class="col-xl-3 col-sm-6">
                        <div class="card">
                            <div class="card-header p-3 pt-2">
                                <div
                                    class="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                    <i class="material-icons opacity-10">deskphone</i>
                                </div>
                                <div class="text-end pt-1">
                                    <p class="text-sm mb-0 text-capitalize">Total Proprieter contacter </p>
                                    <h4 class="mb-0">{{ proprieter_total_nbr_contact }}</h4>
                                </div>
                            </div>
                            <hr class="dark horizontal my-0">

                        </div>
                    </div>

                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card">
                            <div class="card-header p-3 pt-2">
                                <div
                                    class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                                    <i class="material-icons opacity-10">house</i>
                                </div>
                                <div class="text-end pt-1">
                                    <p class="text-sm mb-0 text-capitalize">Mes biens </p>
                                    <h4 class="mb-0">{{ nb_bien }}</h4>
                                </div>
                            </div>
                            <hr class="dark horizontal my-0">

                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card">
                            <div class="card-header p-3 pt-2">
                                <div
                                    class="icon icon-lg icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                                    <i class="material-icons opacity-10">thumb_up</i>
                                </div>
                                <div class="text-end pt-1">
                                    <p class="text-sm mb-0 text-capitalize">Mes likes</p>
                                    <h4 class="mb-0">{{ nb_like }}</h4>
                                </div>
                            </div>
                            <hr class="dark horizontal my-0">

                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card">
                            <div class="card-header p-3 pt-2">
                                <div
                                    class="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                    <i class="material-icons opacity-10">chat</i>
                                </div>
                                <div class="text-end pt-1">
                                    <p class="text-sm mb-0 text-capitalize">Mes commentaires</p>
                                    <h4 class="mb-0">{{ nb_comment }}</h4>
                                </div>
                            </div>
                            <hr class="dark horizontal my-0">

                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6">
                        <div class="card">
                            <div class="card-header p-3 pt-2">
                                <div
                                    class="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                    <i class="material-icons opacity-10">deskphone</i>
                                </div>
                                <div class="text-end pt-1">
                                    <p class="text-sm mb-0 text-capitalize">Mes Proprieter contacter </p>
                                    <h4 class="mb-0">{{ nb_contact }}</h4>
                                </div>
                            </div>
                            <hr class="dark horizontal my-0">

                        </div>
                    </div>
                </div>












                <div class="row my-4">



                    <div class="col-md-6  mb-4">
                        <div class="card">
                            <div class="card-header pb-0">
                                <div class="row">
                                    <div class="col-lg-6 col-7">
                                        <h6>Top utilisateurs</h6>
                                        <p class="text-sm mb-0">
                                            <i class="fa fa-check text-info" aria-hidden="true"></i>
                                            <span v-if="userstop" class="font-weight-bold ms-1">{{ userstop.length
                                            }}</span>
                                        </p>
                                    </div>
                                    <div class="col-lg-6 col-5 my-auto text-end">
                                        <div class="dropdown float-lg-end pe-4">
                                            <a class="cursor-pointer" id="dropdownTable" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <i class="fa fa-ellipsis-v text-secondary"></i>
                                            </a>
                                            <ul class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                                                aria-labelledby="dropdownTable">
                                                <li><a class="dropdown-item border-radius-md" href="javascript:;">Action</a>
                                                </li>
                                                <li><a class="dropdown-item border-radius-md" href="javascript:;">Another
                                                        action</a></li>
                                                <li><a class="dropdown-item border-radius-md" href="javascript:;">Something
                                                        else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body px-0 pb-2">
                                <div class="table-responsive">
                                    <table class="table align-items-center justify-content-center mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    Utilisateur</th>

                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    Adresse</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                                                    Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr v-for="user in userstop" :key="user.id">
                                                <td>
                                                    <div class="d-flex px-2">
                                                        <div>
                                                            <img :src=" user.photo"
                                                                class="avatar avatar-sm rounded-circle me-2" alt="xd">
                                                        </div>
                                                        <div class="my-auto">
                                                            <h6 v-if="user.entreprise" class="mb-0 text-sm">{{
                                                                user.entreprise }}</h6>
                                                            <h6 v-else class="mb-0 text-sm">{{ user.first_name }} {{
                                                                user.last_name }}</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span class="text-xs font-weight-bold">{{ user.addresse }}</span>
                                                </td>
                                                <td class="align-middle text-center">
                                                    <div class="social-profile">

                                                        <a target="_blank"
                                                            :href="'mailto:' + user.email + '?subject=' + encodeURIComponent('Demande de renseignement') + '&body=' + encodeURIComponent('Bonjour, j\'ai vu votre agence immo sur TGV IMMO BRIO')"
                                                            @click="upcontact_us(user.id)"><i
                                                                class="fa fa-envelope"></i></a>
                                                        <a target="_blank"
                                                            :href="'https://wa.me/' + user.whatsapp + '?text=' + encodeURIComponent('Bonjour, j\'ai vu votre agence immo sur TGV IMMO BRIO')"
                                                            @click="upcontact_us(user.id)"><i
                                                                class="fab fa-whatsapp"></i></a>
                                                    </div>

                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6  mb-4">
                        <div class="card">
                            <div class="card-header pb-0">
                                <div class="row">
                                    <div class="col-lg-6 col-7">
                                        <h6>Top des biens</h6>
                                        <p class="text-sm mb-0">
                                            <i class="fa fa-check text-info" aria-hidden="true"></i>
                                            <span v-if="proprieterstop" class="font-weight-bold ms-1">{{
                                                proprieterstop.length }}</span>

                                        </p>
                                    </div>
                                    <div class="col-lg-6 col-5 my-auto text-end">
                                        <div class="dropdown float-lg-end pe-4">
                                            <a class="cursor-pointer" id="dropdownTable" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <i class="fa fa-ellipsis-v text-secondary"></i>
                                            </a>
                                            <ul class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                                                aria-labelledby="dropdownTable">
                                                <li><a class="dropdown-item border-radius-md" href="javascript:;">Action</a>
                                                </li>
                                                <li><a class="dropdown-item border-radius-md" href="javascript:;">Another
                                                        action</a></li>
                                                <li><a class="dropdown-item border-radius-md" href="javascript:;">Something
                                                        else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body px-0 pb-2">
                                <div class="table-responsive">
                                    <table class="table align-items-center justify-content-center mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    Utilisateur</th>

                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    Categorie</th>

                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    Adresse</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                                                    Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr v-for="proprieter in proprieterstop" :key="proprieter.id">
                                                <td>
                                                    <div class="d-flex px-2">
                                                        <div class="my-auto">

                                                            <h6 v-if="proprieter.user.entreprise" class="mb-0 text-sm">{{
                                                                proprieter.user.entreprise }}</h6>
                                                            <h6 class="mb-0 text-sm" v-else>{{ proprieter.user.first_name }}
                                                                {{
                                                                    proprieter.user.last_name }}</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span class="text-xs font-weight-bold">{{ proprieter.categorie.nom
                                                    }}</span>
                                                </td>
                                                <td>
                                                    <span class="text-xs font-weight-bold">{{ proprieter.addresse }}</span>
                                                </td>
                                                <td class="align-middle text-center">
                                                    <div class="social-profile">

                                                        <a target="_blank"
                                                            :href="'mailto:' + proprieter.user.email + '?subject=' + encodeURIComponent('Demande de renseignement') + '&body=' + encodeURIComponent('Bonjour, j\'ai vu ce bien sur TGV IMMO BRIO, ça m\'intéresse. https://tgvimmobrio.com/commentaire?id=' + proprieter.id)"
                                                            @click="upcontact_pro(proprieter.id)"><i
                                                                class="fa fa-envelope"></i></a>
                                                        <a target="_blank"
                                                            :href="'https://wa.me/' + proprieter.user.whatsapp + '?text=' + encodeURIComponent('Bonjour, j\'ai vu ce bien sur TGV IMMO BRIO, ça m\'intéresse. https://tgvimmobrio.com/commentaire?id=' + proprieter.id)"
                                                            @click="upcontact_pro(proprieter.id)"><i
                                                                class="fab fa-whatsapp"></i></a>
                                                    </div>

                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-md-0 mb-4">
                        <div class="card">
                            <div class="card-header pb-0">
                                <div class="row">
                                    <div class="col-lg-6 col-7">
                                        <h6>Demandes</h6>
                                        <p class="text-sm mb-0">
                                            <i class="fa fa-check text-info" aria-hidden="true"></i>
                                            <span v-if="demandes" class="font-weight-bold ms-1">{{ demandes.length
                                            }}</span>
                                        </p>
                                    </div>
                                    <div class="col-lg-6 col-5 my-auto text-end">
                                        <div class="dropdown float-lg-end pe-4">
                                            <a class="cursor-pointer" id="dropdownTable" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <i class="fa fa-ellipsis-v text-secondary"></i>
                                            </a>
                                            <ul class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                                                aria-labelledby="dropdownTable">
                                                <li><a class="dropdown-item border-radius-md" href="javascript:;">Action</a>
                                                </li>
                                                <li><a class="dropdown-item border-radius-md" href="javascript:;">Another
                                                        action</a></li>
                                                <li><a class="dropdown-item border-radius-md" href="javascript:;">Something
                                                        else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body px-0 pb-2">
                                <div class="table-responsive">
                                    <table id="Table" class="table align-items-center justify-content-center mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    Utilisateur</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    Categorie</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    Pays</th>

                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    Ville</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    Budget</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    Details</th>

                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                                                    Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr v-for="demande in demandes" :key="demande.id">
                                                <td>
                                                    <div class="d-flex px-2">
                                                        <div>
                                                            <img :src=" demande.user.photo"
                                                                class="avatar avatar-sm rounded-circle me-2" alt="xd">
                                                        </div>
                                                        <div class="my-auto">
                                                            <h6 class="mb-0 text-sm">{{ demande.user.first_name }} {{
                                                                demande.user.last_name }}</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span class="text-xs font-weight-bold">{{ demande.categorie.nom
                                                    }}</span>
                                                </td>
                                                <td>
                                                    <span class="text-xs font-weight-bold">{{ demande.pays.name }}</span>
                                                </td>
                                                <td>
                                                    <span class="text-xs font-weight-bold">{{ demande.ville }}</span>
                                                </td>
                                                <td>
                                                    <p class="text-sm font-weight-bold mb-0">{{ formatMonetaryValue(
                                                        demande.budget) }} {{ demande.pays.currency_code }}</p>
                                                </td>
                                                <td>
                                                    <p class="text-sm font-weight-bold mb-0">{{ demande.caracteristique }}
                                                    </p>
                                                </td>
                                                <td class="align-middle text-center">
                                                    <div class="social-profile">

                                                        <a target="_blank" :href="'mailto:' + demande.email"
                                                            @click="upcontact_us(demande.user.id)"><i
                                                                class="fa fa-envelope"></i></a>
                                                        <a target="_blank" :href="'https://wa.me/' +demande.whatsapp"
                                                            @click="upcontact_us(demande.user.id)"><i
                                                            class="fab fa-whatsapp"></i></a>
                                                    <button @click="supdemande(demande.id)"
                                                        v-if="user==demande.user.id || role=='admin'"
                                                        class="btn btn-danger"><i
                                                            class="material-icons">delete</i></button>
                                                </div>

                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <FootComponent />
        </div>
    </main>
</body></template>