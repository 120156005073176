<script>
import HeadComponent from "../components/Head"
import FootComponent from '../components/Foot'
import store from '@/store'
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            agenceUser: null,
            validuser: false,
            
            pays: 'BJ',
            country: null,
            addresse: null,
        }
    },
    components: {
        HeadComponent,
        FootComponent
    },
    name: 'AgencesComponent',

    methods: {
        usersAgence() {

            const formData = new FormData()

            if (this.addresse != null) {

                formData.append('addresse', this.addresse)


            }
            if (this.pays != null) {

                formData.append('pays', this.pays)

            }
            store.dispatch('auth/usersAgence', formData)
                .then(response => {
                    console.log(response)
                    this.agenceUser = response
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire({
                        title: "Erreur",
                        text: "Aucun resultat trouver",
                        icon: "error",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    })
                    this.agenceUser = null
                })
        },

        redirect(type, id) {

            if (type == 'pro') {
                this.upcontact_pro(id)

            }
            else if (type == 'us') {
                this.upcontact_us(id)

            }
            alert('Cette action est reservé aux utilisateurs inscrit, Veuillez vous connecter ')

            window.location.replace('/register')

        },
        upcontact_pro(id) {
            store.dispatch('auth/upcontact_pro', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        upcontact_us(id) {
            store.dispatch('auth/upcontact_us', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },

    },
    mounted() {
        document.title = "Agences immobilliere";
        this.usersAgence()
        store.dispatch('categorie/getcountry')
            .then(response => {
                this.country = response

            })
            .catch(error => {
                console.log(error)
            })
        store.dispatch('auth/_currentUser')
            .then(() => {
                this.validuser = true
            })
            .catch(error => {
                console.log(error)

            })
    }

}
</script>

<template>
    <HeadComponent />

    <main>
        <div class="container-fluid">


           
            <h1 class="text-center m-4">Liste des agences</h1>
            <div class="card-body">
                <form @submit.prevent="usersAgence">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="checkout-form-list country-select">
                                <label class="form-label">Pays<span>*</span></label>
                                <select required ref="mySelect" v-model="pays" class="form-control">
                                    <option v-for="countrie in country" :key="countrie.iso" :value="countrie.iso">{{
                    countrie.name }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="checkout-form-list">
                                <label class="form-label">Ville,quartier <span>*</span></label>
                                <input type="text" v-model="addresse" class="form-control">
                            </div>
                        </div>



                        <center>

                            <button type="submit" class="btn theme-btn-2 text-light">
                                Rechercher
                            </button>

                        </center>
                    </div>
                </form>
                <section>
                    <div class="alert alert-danger mt-5" role="alert">
                        Que faut t'il savoir sur l'immobillier dans ce pays. 
                        <a :href="'/article?pays='+pays"
                            class="alert-link">cliquez ici </a> pour en savoir plus
                    </div>
                </section>

            </div>
            <div v-if=" agenceUser " class='row'>
                <div v-for=" aguser  in agenceUser" :key=" aguser.id " class="card col-3" style="width: 18rem;">
                    <img :src="aguser.photo " class="card-img-top" alt="">
                    <div class="card-body">
                        <h5 class="card-title">{{ aguser.entreprise }}</h5>
                        <p class="card-text">{{ aguser.addresse }}</p>

                        <div class="social-profile" v-if=" !validuser ">
                            <a href='' @click="redirect('us', aguser.id)"><i class="fa fa-envelope"></i></a>
                            <a href='' @click="redirect('us', aguser.id)"><i class="fab fa-whatsapp"></i></a>
                            <a :href=" '/search?type=user&id=' + aguser.id "><i class="fa fa-eye"></i></a>

                        </div>

                        <div class="social-profile" v-else>
                            <a target="_blank" @click="upcontact_us(aguser.id)"  v-if='aguser.email_contact'
                            :href=" 'mailto:' + aguser.email + '?subject=' + encodeURIComponent('Demande de renseignement') + '&body=' + encodeURIComponent('Bonjour, j\'ai vu votre agence immo sur TGV IMMO BRIO') "><i
                                    class="fa fa-envelope"></i></a>
                            <a target="_blank" @click="upcontact_us(aguser.id)" v-if='aguser.whatsapp_contact'
                                :href=" 'https://wa.me/' + aguser.whatsapp + '?text=' + encodeURIComponent('Bonjour, j\'ai vu votre agence immo sur TGV IMMO BRIO')"><i class="fab fa-whatsapp"></i></a>

                            <a :href=" '/search?type=user&id=' + aguser.id "><i class="fa fa-eye"></i></a>

                        </div>

                    </div>
                </div>
            </div>


        </div>

    </main>

    <FootComponent />
</template>
<style>
#about-us {
    justify-content: center;
}



#about-us .about-item h2 {
    font-style: italic;
    font-weight: 900;
    font-size: 1.4em;
}

#about-us .about-item i {
    font-size: 2.5em;
    border: 3px solid rgb(205, 127, 50);
    border-radius: 100%;
    padding: 30px;
    color: rgb(205, 127, 50);
    width: 106px;
}
</style>