<script>
import store from '@/store' // Assurez-vous d'importer le routeur

export default {
    name: 'AsideComponent',
    data() {
        return {
            currentUser: null, // Initialisez currentUser à null
            role:null,
            path:null
        };
    },
    mounted() {
        
            this.path= window.location.pathname

            store.dispatch('auth/_currentUser')
                .then(() => {
                    this.currentUser = store.getters['auth/getCurrentUser'];
            this.role=this.currentUser.role
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération de l'utilisateur", error);
                        
                window.location.replace('/login')

                });
       
                
    },
    methods: {
        
        logout() {
            store.dispatch('auth/logout').then(data => {
                console.log(data)
                window.location.replace('/')
            }).catch(error => {
                console.log(error)
            })
        },
        search()
        { 

            window.location.replace('/search?type=search&id='+this.keyword)

        },
        
    },
}
</script>
<template>
    <link id="pagestyle" href="admin/css/material-dashboard.css?v=3.1.0" rel="stylesheet" />
  
    
    <aside
            class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark"
            id="sidenav-main">
            <div class="sidenav-header">
                <i class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                    aria-hidden="true" id="iconSidenav"></i>
                <a class="navbar-brand m-0" href="/dashboard">
                    <img src="img/logo/logo2.jpg" class="navbar-brand-img h-100" alt="main_logo">
                    <span class="ms-1 font-weight-bold text-white">Administration</span>
                </a>
            </div>
            <hr class="horizontal light mt-0 mb-2">
            <div class="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link text-white " :class="{'active bg-gradient-primary':path==='/dashboard'}"  href="/dashboard">
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10">dashboard</i>
                            </div>
                            <span class="nav-link-text ms-1">Dashboard</span>
                        </a>
                    </li>
                    <li v-if="role=='admin'" class="nav-item">
                        <a class="nav-link text-white "  :class="{'active bg-gradient-primary':path==='/users'}" href="/users">
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10">account_box</i>
                            </div>
                            <span class="nav-link-text ms-1">Utilisateurs</span>
                        </a>
                    </li>
                    <li v-if="role=='admin'" class="nav-item">
                        <a class="nav-link text-white " :class="{'active bg-gradient-primary':path==='/categorie'}" href="/categorie">
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10">apps</i>
                            </div>
                            <span class="nav-link-text ms-1">Catégories</span>
                        </a>
                    </li>
                    
                    <li class="nav-item">
                        <a class="nav-link text-white" :class="{'active bg-gradient-primary':path==='/proprieter'}" href="/proprieter">
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10">receipt_long</i>
                            </div>
                            <span class="nav-link-text ms-1">Publier un bien</span>
                        </a>
                    </li>
                    <li v-if="role=='admin'" class="nav-item">
                        <a class="nav-link text-white " :class="{'active bg-gradient-primary':path==='/adm_article'}" href="/adm_article">
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10">article</i>
                            </div>
                            <span class="nav-link-text ms-1">Blog</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white " :class="{'active bg-gradient-primary':path==='/demande'}" href="/demande">
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10">notifications</i>
                            </div>
                            <span class="nav-link-text ms-1">Demandes</span>
                        </a>
                    </li>
                    <li v-if="role=='admin'" class="nav-item">
                        <a class="nav-link text-white " :class="{'active bg-gradient-primary':path==='/banniere'}" href="/banniere">
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10">photo_library</i>
                            </div>
                            <span class="nav-link-text ms-1">Bannières pub</span>
                        </a>
                    </li>

                    
                    <li v-if="role=='admin'" class="nav-item">
                        <a class="nav-link text-white " :class="{'active bg-gradient-primary':path==='/pub'}" href="/pub">
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10">campaign</i>
                            </div>
                            <span class="nav-link-text ms-1">Pop up</span>
                        </a>
                    </li>

                    <li v-if="role=='admin'" class="nav-item">
                        <a class="nav-link text-white " :class="{'active bg-gradient-primary':path==='/news'}" href="/news">
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10">unsubscribe</i>
                            </div>
                            <span class="nav-link-text ms-1">news</span>
                        </a>
                    </li>
                  
                    <li class="nav-item">
                        <a class="nav-link text-white " :class="{'active bg-gradient-primary':path==='/profil'}" href="profil">
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10">person</i>
                            </div>
                            <span class="nav-link-text ms-1">Profile</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="sidenav-footer position-absolute w-100 bottom-0 ">
      <div class="mx-3">
       
        <a class="btn bg-gradient-primary w-100 text-light" @click="logout" type="button">Déconnexion</a>
      </div>
    </div>
        </aside>
</template>